@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.container {
    height: 40rem;
    overflow: auto;
}

.spinner-container {
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    display: inline-flex;
    flex-direction: column;
    height: 100%;
    font-size: var(--font-md);
    font-weight: 700;
}

.subtitle {
    font-size: var(--font-sm);
    font-weight: 400;
}

.controls {
    display: inline-flex;
    flex-direction: row;
    z-index: 100;
}

.body {
    padding: 0 1rem 0 2.4rem;
}

.no-system-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
}

.no-system-subtext {
    margin: 3rem 2.4rem 2rem 2.4rem;
}
