@import '../styles/fonts.css';
@import '../styles/colors.css';

.chart-title {
  font-size: var(--font-lg);
  font-weight: 500;
  color: var(--ibPrimaryDark);
}

.container {
  height: 100vh;
  width: 100vw;
  background-color: var(--lightGray);
  display: flex;
}

.component-header-container {
  border-bottom: 1px solid var(--ash);
}

.component-header-text {
  font-weight: 700;
  font-size: var(--font-xl);
}

.left-nav-container {
  display: flex;
  width: 15%;
  background-color: var(--ash);
}

.list-item-text {
  display: initial;
}

.logo {
  text-align: center;
  cursor: pointer;
  height: 4rem;
  margin: 2rem 0;
}

.left-nav-row-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

@media screen and (max-width: 440px) {
  .list-item-text {
    display: none;
  }
}

@media print {
  .no-print {
    display: none;
  }
}

.sidebar-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--font-xl);
  font-weight: 700;
}

.modal-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--font-xl);
  font-weight: 700;
}

.modal-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.base-pane {
  background-color: var(--antd);
  flex: auto;
}

.page-section {
  margin-top: 3rem;
  border-top: 0.5rem solid;
  border-top-color: var(--ibPrimaryDark);
  border-radius: 0.4rem;
  background-color: var(--white);
}

.page-section-body {
  margin-left: 16px;
  margin-right: 16px;
}

.page-section-filled {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--ibPrimaryDark);
  align-items: center;
  border-radius: 4px;
  padding: 2rem;
}

.page-section-filled-title {
  font-size: var(--font-xl);
  color: var(--white);
  font-weight: 700;
  margin-bottom: 1rem;
}

.page-section-filled-tagline {
  margin-top: 2rem;
  font-size: var(--font-sm);
  color: var(--white);
}

.side-bar-section {
  border-top: 5px solid;
  border-image: var(--ibAccent) 100% 0 0 0;
  border-radius: 0.4rem;
  background: var(--white);
}

.primary-content-pane {
  border-top: 0.5rem solid;
  border-top-color: var(--ibPrimaryDark);
  /* border-image: var(--blue-gradient) 100% 0 0 0; */
  border-radius: 0.4rem;
  background: var(--white);
}

.content-pane {
  background: var(--white);
  flex: auto;
}

.card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Nirmala, Roboto;
  text-transform: uppercase;
  font-size: var(--font-xl);
  color: var(--dark);
  border-bottom: 1px solid var(--antd-borderGray);
  padding: 2rem;
}

.card-header-smaller {
  font-size: var(--font-lg);
}

.card-body {
  padding: 2rem;
}

.card-title {
  font-size: var(--font-lg);
  margin-right: 1rem;
}

.tab-title {
  font-weight: 700;
}

.filter-chips-container {
  display: flex;
  flex-flow: row wrap;
  margin-left: -2rem;
  margin-top: -2rem;
  padding: 0 2rem 2rem 2rem;
}

.filters {
  width: 100%;
  background: var(--blue-gradient);
}

.filters-title {
  /* align-items: center; */
  font-size: var(--font-xl);
  color: var(--white);
  font-weight: 700;
  padding: 1rem 0 1rem 2rem;
  border-bottom: 1px solid var(--white);
}

.filters-title-clean {
  /* align-items: center; */
  font-size: var(--font-xl);
  color: var(--white);
  font-weight: 700;
  padding: 1rem 0 1rem 2rem;
}

.filters-body {
  display: flex;
  align-items: center;
  padding: 2rem;
}

.drop-down-container {
  background-color: var(--white);
  border-radius: 0.125rem;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.form-field-label {
  margin-top: 20px;
  font-size: var(--font-lg);
}

.form-field-label-left {
  margin-top: 20px;
  font-size: var(--font-lg);
}

.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttons {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--antd-borderGray);
  padding: 1rem;
}

.ok-button {
  width: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ok-button:hover {
  background: var(--antd-lightBlue);
  color: var(--white);
}
